<template>

  <section class="carousel-section ">
    <div id="carouselExampleSlidesOnly" class="carousel slide" data-ride="carousel">

      <div class="carousel-inner">
        <div class="carousel-item active">
          <!--<a @click="goTo('freebet')">-->
            <router-link to="/gifts">
            <img class="d-block w-100" src="/img/carousel/karibu1.png">
            </router-link>
          <!--</a>-->
        </div>

       
      </div>
    </div>
  </section>

</template>
<script>
export default {
  name: "SliderCasino",
}
</script>